import { Component, Vue } from 'vue-property-decorator'
import { ResultProblem } from '@/mixins/drillsv2/WebApi'

// ==============================
// === 学習履歴取得API

// 学習履歴レスポンス
export type ResponseResultDrill = {
  resultDrillId: number
  classModeCode: string
  classCategoryCode: string
  questionCount: number
  correctCount: number
  curriculumSUnitIds: number[]
  predictedScores: PredictedScore[]
  resultDrillPages: ResultDrillPage[]
}

// ページごとの結果
type ResultDrillPage = {
  pageNum: number
  problems: ResultProblem[]
}

export type PredictedScore = {
  curriculumSUnitId: number
  min: number
  median: number
  max: number
}

// ==============================
// === 動画視聴履歴登録API

// 動画視聴履歴登録結果
export type ResponseResultMovie = {
  movieId: number
  resultMovieId: number
}

@Component
export default class HistoryApi extends Vue {
  /**
   * 学習履歴取得API
   *
   * @params resultDrillId ドリルID
   * @returns 履歴情報
   */
  protected async getResultDrill(resultDrillId: number): Promise<ResponseResultDrill> {
    // パラメータ生成
    const params = {
      resultDrillId: resultDrillId,
    }

    Vue.prototype.$logger.info('-- HistoryApi getResultDrill')
    Vue.prototype.$logger.info(params)

    const result = await Vue.prototype.$http.httpWithToken.get('/v2/resultDrills', {
      params: params,
    })
    return result.data
  }

  /**
   * 動画視聴履歴登録API
   *
   * @params requestResultMovie 履歴登録
   */
  protected async registResultMovie(
    resultDrillId: number,
    sProblemId: number,
    movieId: number
  ): Promise<ResponseResultMovie> {
    const params = {
      resultDrillId: resultDrillId,
      sProblemId: sProblemId,
      movieId: movieId,
    }

    Vue.prototype.$logger.info('-- HistoryApi registResultMovie')
    Vue.prototype.$logger.info(params)

    const result = await Vue.prototype.$http.httpWithToken.post('/v2/resultMovies', params)
    return result.data
  }

  /**
   * 導入動画視聴履歴登録API
   *
   * @params curriculumSUnitId 小項目ID
   * @params classModeCode 授業モード
   * @params movieId 動画ID
   */
  protected async registResultIntroductionMovie(
    curriculumSUnitId: number,
    classModeCode: string,
    movieId: number
  ): Promise<ResponseResultMovie> {
    const params = {
      curriculumSUnitId: curriculumSUnitId,
      classModeCode: classModeCode,
      movieId: movieId,
    }

    Vue.prototype.$logger.info('-- HistoryApi registResultIntroductionMovie')
    Vue.prototype.$logger.info(params)

    const result = await Vue.prototype.$http.httpWithToken.post('/v2/resultIntroductionMovies', params)
    return result.data
  }

  /**
   * 動画視聴いいね更新API
   *
   * @param resultMovieId 動画視聴履歴ID
   * @param liked いいねの有無
   */
  protected async updateResultMovieLiked(resultMovieId: number, liked: boolean) {
    Vue.prototype.$logger.info('-- HistoryApi updateResultMovieLiked')
    Vue.prototype.$logger.info(`resultMovieId: ${resultMovieId}`)
    Vue.prototype.$logger.info(`liked: ${liked}`)

    await Vue.prototype.$http.httpWithToken.patch(`/v2/resultMovies/${resultMovieId}`, { liked: liked })
  }

  /**
   * 動画視聴完了API
   *
   * @param resultMovieId 動画視聴履歴ID
   */
  protected async completeResultMovie(resultMovieId: number) {
    Vue.prototype.$logger.info('-- HistoryApi completeResultMovie')
    Vue.prototype.$logger.info(`resultMovieId: ${resultMovieId}`)

    await Vue.prototype.$http.httpWithToken.patch(`/v2/resultMovies/${resultMovieId}/complete`)
  }
}
